<template>
    <div class="searchFilter fade !pt-0 !pb-2" :class="[{'fade-in ' : showSearch || inline}, {'fade-out' : temporaryHide}]">
        <div class="input-group pl-2">
            <span class="block"><quote-icon class="!w-4 text-gray-3 mr-2" icon="search" /></span>

            <input 
                ref="searchInput"
                class="form-control w-full text-black"
                :autofocus="autoFocus"
                :value="searchString"
                @input="(event) => {startTyping(event.target.value); updateString(event.target.value);}"
                placeholder="Search">


            <span v-if="hasClose" @click="() => closeSearch()" class="block"><quote-icon class="!w-4 text-gray-3 mr-2" icon="x-mark" /></span>


        </div>
    </div>
</template>

<script>
/* plugins */
import _ from 'lodash'

export default {
    props : {
        showSearch : {
            type   : Boolean,
            default: () => false
        },
        searchString : {
            type   : String,
            default: () => ''
        },
        className : {
            type   : String,
            default: () => ''
        },
        autoFocus : {
            type : Boolean,
            default : () => false
        },
        inline : {
            type : Boolean,
            default : () => false
        },
        hasClose : {
            type : Boolean,
            default : () => false
        }
    },
    data() {
        return {
            temporaryHide : false
        }
    },
    watch : {
        /**
         * Temporary hide 
         */
        showSearch(state) {
            if(!state)  {
                this.temporaryHide = true;   

               
            } else if(this.autoFocus) {
                const inputFld = this.$refs.searchInput
                console.log('auto focus', inputFld)
                if(inputFld) inputFld.focus()
            }

            setTimeout(() => {
                this.temporaryHide = false;
            }, 500);

            
        }
    },
    methods : {
        /**
         * Will triggered as soon start typing
         * @param {String} string
         */
        startTyping (string) {
            console.log('search string', string)
            this.$emit('startTyping', string);
        },
        /* Update search string  */
        updateString : _.debounce(function(str) {
            this.$emit('update:searchString', str)
            this.$emit('input', str)
        }, 100),
        /**
         * Reset and close
         * 
         */
        closeSearch() {
            this.$refs.searchInput.value = ''

            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">

.searchFilter {
    width : 100%;

    .form-control {
        padding-left : 0;
    }
}
</style>