/**
 * Author apis
 * Storing and pulling of author data from local database
 * @namespace AuthorApis
 */

/* Utils */
import { addUpdateCollection, deleteCollection,  getCollections } from "../localbase";

/* Models */
import AuthorModel from "../models/authorModel";

/* Plugins */
import axios from 'axios';
import _ from 'lodash';

/* Resources */
import AuthorResource from "../resources/authorResource";

export default class AuthorApis {

    // /**
    //  * Get total authors
    //  * @param {Number} user_id
    //  * @param {Boolean} 
    //  */

    /**
     * Delete author
     * @param {Number} author_id
     * @param {Funciont} callback
     */
    static async deleteAuthor({author_id, callback = () => {}} = {}) {
        await deleteCollection({name : 'author', filter : {id : parseInt(author_id)}});

        callback();
    }

    /**
     * Sync deleted authors
     */
    static async syncDeletedAuthors() {
        const url = `/api/author/listarchived`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const list = res.data.data;
            list.forEach(l => deleteCollection({name : 'authors', filter : {id : l.id}}))
        }
    }

    /**
     * Update author
     * @param {Object} params
     * @param {Function} callback
     */

    static async updateAuthor({params, callback = () => {}} = {}) {
        addUpdateCollection({name : "authors", data : AuthorModel.schema(params)});

        callback();
    }

    /**
     * Get author positions
     * @param {Number} author_id
     * @param {Function} callback
     */

    static async getAuthorPositions({author_id, callback = () => {}}) {
        const callbackRes = async () => {
            const authorPositions = await getCollections({name : 'authors_positions', filter : {key : 'author_id', value : author_id}});
            callback(authorPositions)
        }

        callbackRes();

        const url = `/api/author/position`
        const res = await axios.get(url, {params : {author_id}}).catch(err => err);

        if(res?.data) {
            const list = res?.data?.data;

            list.forEach(l => addUpdateCollection({name : 'authors_positions', data : l}));

            /* Sync position - remove deleted position of this author */
            const authorPositions = await getCollections({name : 'authors_positions', filter : {key : 'author_id', value : author_id}});
            const removePosition = _.differenceBy(authorPositions, list,  'id');

            removePosition.forEach(p => deleteCollection({name : 'authors_positions', id : p.id}))


            callbackRes();

        }
    }

    /**
     * Get author
     * @param {Number} author_id
     * @param {Function} callback
     */

    static async getAuthor({author_id, callback = () => {}}  = {}) {
        const callbackRes = async () => {
            const author = await AuthorResource.getAuthor({author_id : parseInt(author_id)});

            console.log(author_id, 'get author',author);
            
            callback(author);
        }


        callbackRes();

        const url = `/api/author/${author_id}`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const author = res?.data?.data;
            addUpdateCollection({name : 'authors', data : AuthorModel.schema(author)});

            callbackRes();
        }

    }

    /**
     * Get author list
     * @param {Function} callback
     * @param {Number} user_id
     */
    static async getAuthorsList({user_id = null, callback = () => {}} = {}) {
        const callbackRes = async () => {

            // let currentCount   = 0;
            // let batchedAuthors = [];
            // let fetching       = false;

            // const FetchingAuthors = async () => {
            //     if(fetching) return;
            //           fetching = true;
            //     const list     = [...batchedAuthors[currentCount]];
            //     currentCount++


            //     let authors = [];
            //     for (let index = 0; index < list.length; index++) {
            //         const author = list[index];

            //         authors = [...authors, await AuthorResource.getAuthor({author_id : author.id, user_id})]
                    
            //     }

            //     callback(authors);
            //     fetching = false;
                
            //     /* Return next batch */
            //     if(currentCount < batchedAuthors.length) FetchingAuthors();
            // }

            // await getCollectionsBy({collectionName : 'authors', indexName : 'first_name', callback : async data => {
            //     batchedAuthors = [...batchedAuthors, data]                
            // }})

            // FetchingAuthors();

            const list = await getCollections({name : 'authors'});
            
            let authors = [];
            
            for (let index = 0; index < list.length; index++) {
                const author = list[index];

                authors = [...authors, await AuthorResource.getAuthor({author_id : author.id, user_id})]
                
            }

            callback(authors);
        }

        callbackRes();

        const url = `/api/author/ownandpublished`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const list = [...res?.data?.published, ...res?.data?.yourown];

            const authors = [...list.filter((l, i, a) => a.findIndex(lf => lf.id == l.id) == i )] // remove duplicates
            
            authors.forEach(a => addUpdateCollection({name : 'authors', data : AuthorModel.schema(a)}));

            callbackRes();
        }
    }

    /**
     * Search Author
     * @param {String} searchbase
     * @param {Number} user_id
     * @param {Function} callback
     */
    static async searchAuthor({searchAuthor, user_id,  callback = () => {}} = {}) {
        let list = await getCollections({name : 'authors'})
        console.log(searchAuthor?.toLowerCase(), 'search author', list)
        let authors = []

        list = [...list].filter(l => {
            const name = `${l.first_name} ${l.last_name}`
            return name?.toLowerCase()?.includes(searchAuthor?.toLowerCase())
        })

        

        for (let index = 0; index < list.length; index++) {
            const author = list[index];
            authors = [...authors, await AuthorResource.getAuthor({author_id : author.id, user_id})]
        }

        console.log('search result ', authors)

        callback(authors)
    }
}