import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import navguard from './navguard'
import store from '../store'

/* Handle router error */
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// };


const routes = [
  {
    path     : '/startpage',
    name     : 'startPage',
    component: () => import('../views/screenLoader.vue'),
    meta : {
      page : 'startpage',
      roles : ['public']
    }
  },
  {
    path     : '/feots',
    name     : 'startPage',
    component: () => import('../views/feots.vue'),
    meta : {
      page : 'feots',
      roles : ['admin']
    }
  },
  {
    path     : '/superadmin',
    component: () => import('../views/superAdmin.vue'),
    children : [
      {
        path     : '',
        name     : 'superAdmin',
        component: () => import('../views/superAdminMenu.vue'),
        meta : {
          page : 'superadmin',
          roles : ['admin']
        }
      },
      {
        path     : 'logs',
        name     : 'superAdminLogs',
        component: () => import('../views/adminLogs.vue'),
        meta : {
          page : 'superadmin',
          roles : ['admin']
        }
      },
      {
        path     : 'logview/:id',
        name     : 'superAdminLogView',
        component: () => import('../views/adminLogView.vue'),
        meta : {
          page : 'superadmin',
          roles : ['admin']
        }
      }
    ]
  },
  {
    path     : '/logs',
    name     : 'adminLogs',
    component: () => import('../views/adminLogs.vue'),
    meta : {
      page : 'logs',
      roles : ['admin']
    }
  },
  {
    path     : '/login',
    name     : 'login',
    component: () => import('../views/login.vue'),
    meta : {
      page : 'login',
      roles : ['public']
    }
  },
  {
    path     : '/verified',
    name     : 'emailVerfied',
    component: () => import('../views/emailVerfied.vue'),
    meta : {
      page : 'emailVerfied',
      roles : ['public']
    }
  },
  {
    path     : '/',
    name     : 'home',
    component: Home,
    meta : {
      page    : 'home',
      viewType: 'personal',
      roles   : ['user']
    }
  },
  {
    path     : '/library',
    children : [
      {
        path     : 'favorites',
        name     : 'libraryFavorites',
        component: () => import('../views/Home.vue'),
        meta : {
          viewType: 'public',
          page    : 'favorites',
          roles   : ['user']
        }
      },
      {
        path     : 'quoteview/:id',
        name     : 'libraryQuoteview',
        component: () => import('../views/quoteedit.vue'),
        meta : {
          viewType: 'public',
          page    : 'libraryQuoteview',
          roles   : ['user']
        }
      },
      {
        path     : 'editquote/:id',
        name     : 'libraryEditquote',
        component: () => import('../views/quoteedit.vue'),
        meta : {
          viewType: 'public',
          page    : 'libraryEditquote',
          roles   : ['user']
        }
      },
      {
        path     : '/library',
        name     : 'library',
        component: () => import('../views/library.vue'),
        meta : {
          viewType: 'public',
          page : 'library',
          roles : ['user']
        }
      },
      {
        path     : 'authors',
        name     : 'libraryAuthors',
        component: () => import('../views/authorslist.vue'),
        meta : {
          viewType: 'public',
          page    : 'authors',
          roles   : ['user']
        }
      },
    
      {
        path     : 'author/:id',
        name     : 'libraryAuthorProfile',
        component: () => import('../views/authorprofile.vue'),
        meta : {
          viewType: 'public',
          page    : 'authors',
          roles   : ['user']
        }
      },
      {
        path     : 'tags',
        name     : 'libraryTags',
        component: () => import('../views/tags.vue'),
        meta : {
          viewType: 'public',
          page    : 'tags',
          roles   : ['user']
        }
      },
      {
        path     : 'view/tag/:id',
        name     : 'libraryTagview',
        component: () => import('../views/tagview.vue'),
        meta : {
          viewType: 'public',
          page    : 'tags',
          roles   : ['user']
        }
      },
      {
        path     : 'sources',
        name     : 'librarySources',
        component: () => import('../views/sourceslist.vue'),
        meta : {
          viewType: 'public',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'compilation/sources/:id',
        name     : 'libraryCompilationSources',
        component: () => import('../views/compilationSources.vue'),
        meta : {
          viewType: 'public',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'view/source/:id',
        name     : 'librarySourceview',
        component: () => import('../views/sourceview.vue'),
        meta : {
          viewType: 'public',
          page    : 'sources',
          roles   : ['user']
        }
      },
    ]
  },
  {
    path     : '/settings',
    component: () => import('../views/settings.vue'),
    children : [
      {
        path     : '/settings',
        name     : 'settings',
        component: () => import('../views/settingsmenu.vue'),
        meta     : {
          viewType: 'personal',
          page    : 'settings',
          roles   : ['user']
        }
      },
      {
        path     : '/user',
        name     : 'userprofile',
        component: () => import('../views/userprofile.vue'),
        meta : {
          viewType: 'personal',
          page : 'settings',
          roles : ['user']
        }
      },
      {
        path     : '/app',
        name     : 'appsettings',
        component: () => import('../views/appsettings.vue'),
        meta : {
          viewType: 'personal',
          page    : 'settings',
          roles   : ['user']
        }
      },
    ]
  },
  {
    path     : '/favorites',
    name     : 'favorites',
    component: () => import('../views/Home.vue'),
    meta : {
      viewType: 'personal',
      page    : 'favorites',
      roles   : ['user']
    }
  },
  {
    path     : '/editquote/:id',
    name     : 'editquote',
    component: () => import('../views/quoteedit.vue'),
    meta : {
      viewType: 'personal',
      page    : 'editquote',
      roles   : ['user']
    }
  },
  {
    path     : '/quoteview/:id',
    name     : 'quoteview',
    component: () => import('../views/quoteedit.vue'),
    meta : {
      viewType: 'personal',
      page    : 'quoteview',
      roles   : ['user']
    }
  },
  {
    path     : '/newquote',
    name     : 'newquote',
    component: () => import('../views/newquote.vue'),
    meta : {
      viewType: 'personal',
      page    : 'newquote',
      roles   : ['user']
    }
    
  },
  {
    path     : '/authors',
    children : [
      {
        path     : '/authors',
        name     : 'authors',
        component: () => import('../views/authorslist.vue'),
        meta : {
          viewType: 'personal',
          page    : 'authors',
          roles   : ['user']
        }
      },
      {
        path     : 'author/:id',
        name     : 'authorprofile',
        component: () => import('../views/authorprofile.vue'),
        meta : {
          viewType: 'personal',
          page    : 'authors',
          roles   : ['user']
        }
      },
      {
        path     : 'author/edit/:id',
        name     : 'authoreditprofile',
        component: () => import('../views/authoreditprofile.vue'),
        meta : {
          viewType: 'personal',
          page : 'authors',
          roles : ['user']
        }
      },
      {
        path     : 'new',
        name     : 'newAuthor',
        component: () => import('../views/authoreditprofile.vue'),
        meta : {
          viewType: 'personal',
          page    : 'authors',
          roles   : ['user']
        }
      },

    ]
  },
  {
    path     : '/sources',
    children : [
      {
        path     : '/sources',
        name     : 'sources',
        component: () => import('../views/sourceslist.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'new',
        name     : 'newSource',
        component: () => import('../views/editsource.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'view/:id',
        name     : 'sourceview',
        component: () => import('../views/sourceview.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'edit/:id',
        name     : 'editsource',
        component: () => import('../views/editsource.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'compilation/edit/:id',
        name     : 'editCompilation',
        component: () => import('../views/editCompilation.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'compilation/new',
        name     : 'newCompilation',
        component: () => import('../views/editCompilation.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      },
      {
        path     : 'compilation/sources/:id',
        name     : 'compilationSources',
        component: () => import('../views/compilationSources.vue'),
        meta : {
          viewType: 'personal',
          page    : 'sources',
          roles   : ['user']
        }
      }
    ]
  },
  {
    path     : '/tags',
    component: () => import('../views/tagsmain.vue'),
    children : [
      {
        path     : '/tags',
        name     : 'tags',
        component: () => import('../views/tags.vue'),
        meta : {
          viewType: 'personal',
          page    : 'tags',
          roles   : ['user']
        }
      },
      {
        path     : 'view/:id',
        name     : 'tagview',
        component: () => import('../views/tagview.vue'),
        meta : {
          viewType: 'personal',
          page    : 'tags',
          roles   : ['user']
        }
      },
      {
        path     : 'edit/:id',
        name     : 'edittag',
        component: () => import('../views/edittag.vue'),
        meta : {
          viewType: 'personal',
          page    : 'tags',
          roles   : ['user']
        }
      },
      {
        path     : 'new',
        name     : 'newTag',
        component: () => import('../views/edittag.vue'),
        meta : {
          viewType: 'personal',
          page    : 'tags',
          roles   : ['user']
        }
      },
    ]
  },
  {
    path : '/public/approval',
    name: 'approvalList',
    component : () => import('../views/publicApproval.vue'),
    meta : {
      page : 'approvalList',
      roles: ['admin']
    }
  },
  {
    path : '/public/quotes',
    name: 'publicQuoteList',
    component : () => import('../views/publicQuotes.vue'),
    meta : {
      page : 'publicQuoteList',
      roles: ['admin']
    }
  },
  {
    path : '/users',
    component : () => import('../views/userMain.vue'),
    children : [
      {
        path : '/users',
        name: 'users',
        component : () => import('../views/userList.vue'),
        meta : {
          page : 'users',
          roles: ['admin']
        },
      },
      {
        path : 'newuser',
        name: 'newUser',
        component : () => import('../views/adminUserView.vue'),
        meta : {
          page : 'users',
          roles: ['admin']
        },
      },
      {
        path : 'userview/:id',
        name: 'userView',
        component : () => import('../views/adminUserView.vue'),
        meta : {
          page : 'users',
          roles: ['admin']
        },
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// NAVIGATION GUARD CHECKER
router.beforeEach( async (to, from, next) => {
  return navguard({to, from, next, store});
});


export default router
