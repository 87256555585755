<template>
     <m-clear-btn
        @click="$router.back()"
        class="flex items-center text-base font-semibold text-orange"
        >
            <quote-icon class="w-5 mr-1" icon="chevron-left"></quote-icon> <slot> </slot>
        </m-clear-btn>
</template>

<script setup>
</script>