import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Vue.config.productionTip = false

/**
 * Global declerations
 */

/* Components */
import quoteIcons from '@/quoteIcons/quote-icons';
import dropdown from '@/components/dropdown';
import Modal from '@/components/modal'
import searchInput from '@/components/searchinput'
import DropZone from '@/components/fileDropZone'
import DropZonePop from '@/components/fileDropZonePop'
import mButtonSecondary from './components/mButtonSecondary.vue'
import backBtn from './components/backBtn.vue'
import clearBtn from './components/clearBtn.vue'
import tablePagination from './components/tablePagination.vue'

// Directive
import {swipelistener, clickAway, dragScroll, isVisible, contentEditor, paragraphFormat, autowidth, Masking} from './directives';
// import VueMask from 'v-mask'
// Plugins
import "./plugins";
import { VueQueryPlugin } from '@tanstack/vue-query'

// import VueCrontab from 'vue-crontab'


/* Fitlers */
import './filters';

const app = createApp(App)
  .use(store)
  .use(router)
  // .use(VueCrontab)

/* Component registrations */
app.component('quote-icon', quoteIcons);
app.component('dropdown', dropdown);
app.component('modal', Modal);
app.component('search', searchInput);
app.component('m-search', searchInput);
app.component('file-drop-zone', DropZone);
app.component('file-drop-zone-pop', DropZonePop);
app.component('m-secondary-btn', mButtonSecondary);
app.component('m-back-btn', backBtn)
app.component('m-clear-btn', clearBtn)
app.component('m-table-pagination', tablePagination)

/* Directive registration */
app.directive('swipelistener', swipelistener);
app.directive('clickAway', clickAway);
app.directive('drag-scroll', dragScroll);
app.directive('isVisible', isVisible);
app.directive('content-editor', contentEditor);
app.directive('paragraph-format', paragraphFormat);
app.directive('autowidth', autowidth);
// app.directive('mask', VueMask);
app.directive('mask', Masking);

/* Plugins */
app.use(VueQueryPlugin)


app.mount('#app')


// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
