<template>
    <div class="flex items-center ">
        <m-clear-btn @click="() => navigate('prev')" class="pr-5"><quote-icon class="w-5" icon="chevron-left" /></m-clear-btn>
        <div class="px-4">{{currentPage}} | {{ totalPage }}</div>
        <m-clear-btn @click="() => navigate('next')" class="pl-5"><quote-icon class="w-5" icon="chevron-right" /></m-clear-btn>
    </div>
</template>

<script setup>
/* ========= UTILS ======== */
import { defineProps, defineEmits } from 'vue';

/* ========= PROPS ======== */
const props = defineProps({
    currentPage : {type : Number , default : () => 1},
    totalPage : {type : Number , default : () => 1},
})
/* ========= EMITS ======== */
const emit =defineEmits(['prev', 'next'])
/* ========= METHODS ======== */

/**
 * Navigation prev or next
 * @param {String} to
 */
const navigate = (to) => {
    if(to == 'prev') {
        if(props.currentPage < 2) return
        const page = props.currentPage - 1
        emit('prev', page)


        return
    } 

    if(props.currentPage >= props.totalPage) return
        const page = props.currentPage + 1
        emit('next', page)
}
</script>