<template>
    <button @click="() => emit('click')" block class="btn py-2 btn-secondary-white w-full">
        <slot></slot>
    </button>
</template>

<script setup>
/* =========== UTILS ======== */
import { defineEmits } from 'vue';



/* =========== EMITS ======== */
const emit = defineEmits(['click'])
</script>